import { AppContext, AppProps } from 'next/app';
import { ApolloProvider } from '@apollo/client';
import Head from 'next/head';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { FontPreload, theme } from '@care/material-ui-theme';
import { GtmHelper } from '@care/google-tag-manager';
import React from 'react';
import mediaQuery from 'css-mediaquery';
import Cookies from 'universal-cookie';
import { setUser } from '@sentry/nextjs';
import { FeatureFlagsProvider } from '@/components/contexts/FeatureFlagsContext';
import { PageAttributesProvider } from '@/components/contexts/PageAttributesContext';
import logger from '@/lib/clientLogger';
import { FeatureFlags, ErrorData, CommonPageAttributes } from '@/types/common';
import PreloadHeroImg from '@/components/PreloadHeroImg';
import { seoSegmentationListingPage_getSEOSegmentationHeroImage as HeroImage } from '@/__generated__/seoSegmentationListingPage';
import {
  FLOURISH_LEFT_IMAGE_URLS,
  CZEN_VISITOR_COOKIE_KEY,
  CZEN_SESSION_COOKIE_KEY,
  COST_GUIDE_HERO_BG_IMG_URL,
} from '@/constants';
import createRelativeImageURL from '@/utils/createRelativeImageURL';
import { getApolloClient } from '@/lib/ApolloClient';
import { GtmService } from '@/utils/GtmService';

interface SeoPageProps {
  pageData: any;
  deviceChar: any;
  ldClientFlags: FeatureFlags;
  seoSegmentId: string;
  userGeoInfo: string;
}

type SeoAppProps = AppProps & {
  czenVisitorId?: string;
  czenSessionId?: string;
  careDeviceId?: string;
  nonce?: string;
};

export default function App({
  Component,
  pageProps,
  careDeviceId,
  czenSessionId,
  czenVisitorId,
  nonce,
}: SeoAppProps) {
  const cache = createCache({ key: 'emotion-cache', nonce, prepend: true });
  const seoPageProps = pageProps as SeoPageProps;
  const heroImage: HeroImage = seoPageProps?.pageData?.getSEOSegmentationHeroImage;
  const deviceChar: any = seoPageProps?.deviceChar;
  const ldClientFlags = seoPageProps?.ldClientFlags;
  const avgPayRate = seoPageProps?.pageData?.getSEOSegmentationDetails?.avgPayRate?.rate?.amount;
  const pageAttributes: CommonPageAttributes = {
    avgPayRate: avgPayRate ? `$${Number(avgPayRate).toFixed(2)}` : null,
    deviceChar,
    userGeoInfo: seoPageProps?.userGeoInfo,
    segmentationSubService: seoPageProps?.pageData?.getSEOSegmentationDetails?.subService,
    seoSegmentId: seoPageProps?.seoSegmentId,
    serviceId: seoPageProps?.pageData?.getSEOSegmentationDetails?.serviceId,
    serviceIdForMember: seoPageProps?.pageData?.getSEOSegmentationDetails?.serviceIdForMember,
    segmentNamePlural: seoPageProps?.pageData?.getSEOSegmentationDetails?.segmentNamePlural,
    segmentNamePluralLowerCase:
      seoPageProps?.pageData?.getSEOSegmentationDetails?.segmentNamePluralLowerCase,
    segmentNameSingular: seoPageProps?.pageData?.getSEOSegmentationDetails?.segmentNameSingular,
    segmentNameSingularLowerCase:
      seoPageProps?.pageData?.getSEOSegmentationDetails?.segmentNameSingularLowerCase,
    pageType: seoPageProps?.pageData?.getSEOSegmentationDetails?.pageType,
    city: seoPageProps?.pageData?.getSEOSegmentationDetails?.city,
    state: seoPageProps?.pageData?.getSEOSegmentationDetails?.state,
    totalNumberOfResults:
      seoPageProps?.pageData?.getSEOSegmentationSearchResults?.pagination?.totalNumberOfResults,
    avgReviewRating: seoPageProps?.pageData?.getSEOSegmentationDetails?.avgReviewRating,
    searchingFor: seoPageProps?.pageData?.getSEOSegmentationDetails?.searchingFor,
    seoPageId: seoPageProps?.pageData?.getSEOSegmentationDetails?.seoPageMetadata?.seoPageId,
  };
  const isCostGuide = seoPageProps?.seoSegmentId?.includes('/cost');

  const getFlourishImage = () => {
    if (deviceChar?.is_mobile === 'true') return FLOURISH_LEFT_IMAGE_URLS.smallImageURL;
    return FLOURISH_LEFT_IMAGE_URLS.largeImageURL;
  };

  const apolloOptions = {
    logError(errorData: ErrorData) {
      const { errorMsg, type, path, payload, serverError } = errorData;

      logger.error(errorMsg, {
        tags: [type],
        fields: {
          category: 'seo',
        },
        path,
        payload,
        serverError,
      });
    },
    // noop until we fix the client logger endpoint
    logInfo() {
      return undefined;
    },
  };
  const apolloClient = getApolloClient(apolloOptions);

  // Push event and slot data to gtm dataLayer on page load
  React.useEffect(() => {
    GtmService.pushEvent({ slots: ['/us-subscription/segment/nonmember/'] });
  }, []);

  React.useEffect(() => {
    GtmHelper.pushFromSessionStorageUsingGtmService(GtmService);
  }, []);

  const ssrMatchMedia = (query: string) => {
    return {
      matches: mediaQuery.match(query, {
        // The estimated CSS width of the browser.
        width: deviceChar?.is_mobile === 'true' ? '1px' : '1400px',
      }),
    };
  };

  theme.components = {
    ...theme.components,
    // Change the default options of useMediaQuery
    MuiUseMediaQuery: {
      defaultProps: {
        ssrMatchMedia,
      },
    },
  };

  // we're using `useRef` to ensure that RUM user attributes are set just once and before any child components are rendered
  const RUMUserInitializedRef = React.useRef<boolean>(false);
  if (!RUMUserInitializedRef.current) {
    setUser({
      id: careDeviceId,
      careDeviceId,
      czenSessionId,
      czenVisitorId,
    });

    RUMUserInitializedRef.current = true;
  }

  return (
    <>
      <Head>
        {heroImage && <PreloadHeroImg heroImage={heroImage} deviceChar={deviceChar} />}
        {isCostGuide && (
          <link
            rel="preload"
            fetchpriority="high"
            href={`${createRelativeImageURL(COST_GUIDE_HERO_BG_IMG_URL)}?im=`}
            as="image"
          />
        )}
        {!isCostGuide && (
          <link
            rel="preload"
            fetchpriority="high"
            href={`${createRelativeImageURL(getFlourishImage())}?im=`}
            as="image"
          />
        )}
        <FontPreload
          fonts={{
            ProximaNova: ['regular', 'bold'],
          }}
        />
        <style
          nonce={nonce}
          // body style - https://github.com/vercel/next.js/issues/10285#issuecomment-624656663
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
        body {
          display: block !important;
        }
      `,
          }}
        />
      </Head>

      <CacheProvider value={cache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ApolloProvider client={apolloClient}>
            <FeatureFlagsProvider flags={ldClientFlags}>
              <PageAttributesProvider commonPageAttributes={pageAttributes}>
                <Component {...pageProps} />
              </PageAttributesProvider>
            </FeatureFlagsProvider>
          </ApolloProvider>
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}

// Invoked during SSR to build the map of data we need to provide to the client.
// This function also serves to disable static page generation and force server-side rendering,
// which is required in order to inject the correct config at runtime rather than build time
export async function getInitialProps(appContext: AppContext) {
  const appGetInitialPropsStart = performance.now();
  const {
    ctx: { req },
  } = appContext;
  let czenVisitorId;
  let czenSessionId;
  let careDeviceId;

  const nonce = req?.careContext?.nonce;

  if (req) {
    const cookies = new Cookies(req.headers.cookie);

    // attempt to grab careDeviceID off the launch darkly user
    const { careDeviceID } = req.careContext?.ldUser?.custom ?? {};
    if (typeof careDeviceID === 'string' && careDeviceID.length) {
      careDeviceId = careDeviceID;
    }

    czenVisitorId = cookies.get(CZEN_VISITOR_COOKIE_KEY);
    czenSessionId = cookies.get(CZEN_SESSION_COOKIE_KEY);

    const appGetInitialPropsStop = performance.now();
    req.careContext.serverTimings.appGetInitialProps =
      appGetInitialPropsStop - appGetInitialPropsStart;
  }

  return {
    czenVisitorId,
    czenSessionId,
    careDeviceId,
    nonce,
  } as SeoAppProps;
}

App.getInitialProps = getInitialProps;
