import { createContext, useContext, ReactNode, useState } from 'react';
import { CommonPageAttributes } from '@/types/common';

interface PageAttributesProviderProps {
  children: ReactNode;
  commonPageAttributes: CommonPageAttributes;
}

const initialState = {
  avgPayRate: null,
  deviceChar: null,
  userGeoInfo: null,
  segmentationSubService: '',
  seoSegmentId: '',
  serviceId: '',
  serviceIdForMember: '',
  segmentNamePlural: '',
  segmentNamePluralLowerCase: '',
  segmentNameSingular: '',
  segmentNameSingularLowerCase: '',
  pageType: undefined,
  city: null,
  state: null,
  totalNumberOfResults: '',
  avgReviewRating: null,
};

const PageAttributesContext = createContext<CommonPageAttributes>(initialState);

export const PageAttributesProvider = ({
  children,
  commonPageAttributes,
}: PageAttributesProviderProps) => {
  const [state] = useState(commonPageAttributes);

  return <PageAttributesContext.Provider value={state}>{children}</PageAttributesContext.Provider>;
};

export const usePageAttributes = () => useContext(PageAttributesContext);
