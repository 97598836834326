import { LDClient, LDUser } from 'launchdarkly-node-server-sdk';
import { createContext, useContext, ReactNode, useState } from 'react';
import { FeatureFlags } from '@/types/common';

interface FeatureFlagsProviderProps {
  children: ReactNode;
  flags: FeatureFlags | undefined;
}

export type FeatureFlagsState = {
  flags: FeatureFlags | undefined;
};

const initialState = {
  flags: {},
};

export type LDRequestContext = { ldClient?: LDClient; ldUser?: LDUser };

const FeatureFlagsContext = createContext<FeatureFlagsState>(initialState);

export const FeatureFlagsProvider = ({ children, flags }: FeatureFlagsProviderProps) => {
  const [state] = useState({ flags });

  return <FeatureFlagsContext.Provider value={state}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);
