import createRelativeImageURL from '@/utils/createRelativeImageURL';
import { seoSegmentationListingPage_getSEOSegmentationHeroImage as HeroImage } from '@/__generated__/seoSegmentationListingPage';

interface Props {
  heroImage: HeroImage;
  deviceChar: any;
}

const PreloadHeroImg = ({ deviceChar, heroImage }: Props) => {
  return (
    <link
      data-testid="hero-image-preload"
      rel="preload"
      fetchpriority="high"
      href={
        deviceChar?.is_mobile === 'true'
          ? `${createRelativeImageURL(heroImage.smallImageURL)}?im=`
          : `${createRelativeImageURL(heroImage.largeImageURL)}?im=`
      }
      as="image"
    />
  );
};

export default PreloadHeroImg;
