import getConfig from 'next/config';

const createRelativeImageURL = (url: string) => {
  const isLocal = process.env.NODE_ENV === 'development';
  const {
    publicRuntimeConfig: { LOCAL_IMAGE_ROOT },
  } = getConfig();

  try {
    if (isLocal) {
      return new URL(url, LOCAL_IMAGE_ROOT).href;
    }
    return new URL(url).pathname;
  } catch (e) {
    return url.toString();
  }
};

export default createRelativeImageURL;
